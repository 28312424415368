"use client";
import { cn } from "@/helpers/className";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import {PodcastTeaser} from "@/components/molecules/PodcastTeaser.tsx";

import '@splidejs/react-splide/css/core';
import '@splidejs/react-splide/css';

interface PodcastSliderProps {
    className?: string;
    podcastData: any;
    position: number;
    count: number;
    parentTitle: string;
    parentType: string;
    parentID: string;
}

export const PodcastSlider = ({ className, podcastData, position, count, parentTitle, parentID, parentType }: PodcastSliderProps) => {
    const gap = '1rem';
    return (
        <div className={cn(
            "item-slider",
            className,
        )}>
            <Splide
                options={{
                    arrows: true,
                    arrowPath: 'M29.7,21.1c-3.8,8-9.8,14.4-17.9,18.9l-1.8-3.7c6.4-3.6,11.2-8.3,14.7-14.2l.8-1.2c.3-.5.3-1.2,0-1.8l-.7-1.2c-3.4-5.9-8.3-10.6-14.7-14.2l1.8-3.7c8.1,4.5,14.1,10.9,17.9,18.9.3.7.3,1.5,0,2.2h0Z',
                    gap: gap,
                    mediaQuery: "min",
                    pagination: false,
                    autoWidth: true,
                    perPage: 1,
                    breakpoints: {
                        480: {
                            //perPage: 2,
                        },
                        600: {
                            //perPage: 3,
                        },
                        960: {
                            perPage: 1,
                            //fixedWidth: `calc(calc(100% - ${gap}) / 2)`,
                        },
                        1280: {
                            perPage: 3,
                        },
                        1600: {
                            perPage: 3,
                        },
                    },
                    classes: {
                        pagination: "splide__pagination !justify-start gap-2 !mt-6",
                        page: "splide__pagination__page w-[0.65rem] h-[0.65rem] rounded-full bg-border",
                    },
                  i18n: {
                    prev: 'Vorig carousel item',
                    next: 'Volgend carousel item',
                  }
                }}
            >
                {podcastData.data.list.items.map((podcast, index) => (
                    <SplideSlide key={index}>
                        <PodcastTeaser
                            key={index}
                            data={podcast}
                            position={position}
                            listPosition={index+1}
                            count={count}
                            listCount={podcastData.data.list.items.length}
                            parentTitle={parentTitle}
                            parentType={parentType}
                            parentID={parentID}
                        />
                    </SplideSlide>
                ))}
            </Splide>
        </div>
    );
};