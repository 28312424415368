'use client';

import React from 'react';
import Button from '@/components/atoms/Button';
import ListClickTracking from "@/components/tracking/ListClickTracking.tsx";

interface ListButtonProps {
  data?: any;
  title?: string;
  image?: any;
  count?: string;
  position?: string;
  link?: any;
  buttonColor: any;
  className: string;
}

const ListButton = ({ data, image, title, count, position, link, buttonColor, className }: ListButtonProps) => {
  const handleListClick = () => {
    try {
      ListClickTracking({
        listTitle: title ?? "",
        componentName: data.__typename ?? "",
        componentID: data.id ?? "",
        componentCount: count ?? "",
        componentPosition: position ?? "",
        imageSource: image?.url ?? "",
        targetUrl: link?.url ?? "",
        targetPageId: link?.url ?? "",
        linkTitle: link?.title ?? "",
        linkType: link?.attributes?.style ?? "default",
      });
    } catch (error) {
      console.error('Error in BannerClickTracking:', error);
    }
  };

  return (
    <Button
      href={link.url}
      as="link"
      className={className}
      color={buttonColor}
      target={link?.attributes?.target ?? ""}
      onClick={handleListClick}
    >
      {link.title}
    </Button>
  );
};

export default ListButton;