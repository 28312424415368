"use client";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import {TeaserNews} from "@/components/molecules/TeaserNews.tsx";

import '@splidejs/react-splide/css/core';
import '@splidejs/react-splide/css';
import {cn} from "@/helpers/className.ts";

interface RelatedSliderProps {
  className?: string;
  bgColor: string;
  finalItems: any[];
  position: number;
  count: number;
  parentTitle: string;
  parentType: string;
  parentID: string;
}

export const RelatedSlider = ({
                                className,
                                finalItems,
                                bgColor,
                                parentID,
                                parentType,
                                parentTitle,
                                position,
                                count
                              }: RelatedSliderProps) => {
  const gap = '1rem';
  return (
    <>
      <div className={cn(
        "item-slider",
        className,
      )}>
        <Splide
          options={{
            arrows: true,
            arrowPath: 'M29.7,21.1c-3.8,8-9.8,14.4-17.9,18.9l-1.8-3.7c6.4-3.6,11.2-8.3,14.7-14.2l.8-1.2c.3-.5.3-1.2,0-1.8l-.7-1.2c-3.4-5.9-8.3-10.6-14.7-14.2l1.8-3.7c8.1,4.5,14.1,10.9,17.9,18.9.3.7.3,1.5,0,2.2h0Z',
            gap: gap,
            perPage: 1,
            mediaQuery: "min",
            pagination: false,
            breakpoints: {
              400: {
                perPage: 1,
                fixedWidth: `calc(calc(100% - calc(${gap} * 2)) / 2)`,
              },
              900: {
                perPage: 3,
                fixedWidth: `calc(calc(100% - calc(${gap} * 3)) / 3)`,
              },
            },
            classes: {
              pagination: "splide__pagination !justify-start gap-2 !mt-6",
              page: "splide__pagination__page w-[0.65rem] h-[0.65rem] rounded-full bg-border",
            },
            i18n: {
              prev: 'Vorig carousel item',
              next: 'Volgend carousel item',
            }
          }}
        >
          {finalItems.map((item, index) => (
            <SplideSlide key={index}>
              <TeaserNews
                key={item.id}
                bgColor={bgColor}
                title={item.title}
                thema={item.thema}
                media={item.teaserMedia?.imageMediaImage ?? undefined}
                link={{
                  url: item.path,
                  internal: true,
                }}
                position={position}
                listPosition={index + 1}
                count={count}
                listCount={finalItems.length}
                parentTitle={parentTitle}
                parentType={parentType}
                parentID={parentID}
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </>
  );
};
