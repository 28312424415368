'use client';

import React from 'react';
import Button from '@/components/atoms/Button';
import BannerClickTracking from "@/components/tracking/BannerClickTracking.tsx";

interface BannerButtonProps {
  data?: any;
  image?: any;
  count?: string;
  position?: string;
  link?: any;
  buttonColor: any;
}

const BannerButton = ({ data, image, count, position, link, buttonColor }: BannerButtonProps) => {
  const handleButtonClick = () => {
    try {
      BannerClickTracking({
        bannerTitle: data.paragraphBannerTitle ?? "",
        componentName: data.__typename ?? "",
        componentID: data.id ?? "",
        componentCount: count ?? "",
        componentPosition: position ?? "",
        imageSource: image.url ?? "",
        targetUrl: link.url ?? "",
        targetPageId: link.url ?? "",
        linkTitle: link.title ?? "",
        linkType: link.attributes.style ?? "",
      });
    } catch (error) {
      console.error('Error in BannerClickTracking:', error);
    }
  };

  return (
    <Button
      href={link.url}
      as="link"
      className="btn-lg"
      color={buttonColor}
      target={link.attributes.target}
      onClick={handleButtonClick}
    >
      {link.title}
    </Button>
  );
};

export default BannerButton;