import DrupalImage from "@/components/atoms/DrupalImage";
import {
  FragmentImageFragment,
  ImageStyleAvailable,
} from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { getVariation } from "@/helpers/image";
import Icon from "@/components/atoms/Icon.tsx";
import { mapTonSurTonColor } from "@/helpers/colors";
import React from "react";
import NewsLink from "@/components/atoms/NewsLink.tsx";
import HorizontalListVisibilityTracker from "@/components/tracking/HorizontalListVisibilityTracker.tsx";

interface TeaserNewsProps {
  bgColor?: string;
  description?: string;
  link?: {
    internal: boolean;
    title: string;
    url: string;
  };
  media?: FragmentImageFragment;
  play_button: boolean;
  thema: object;
  title: string;
  position: number;
  listPosition: number;
  count: number;
  listCount: number;
  parentTitle: string;
  parentType: string;
  parentID: string;
}

export const TeaserNews = ({ title, description, thema, media, link, play_button, bgColor, parentTitle, parentType, parentID, position, listPosition, listCount, count }: TeaserNewsProps) => {
  const mobileImage = media?.variations
    ? getVariation(media.variations, ImageStyleAvailable.LandscapeRatio32)
    : null;
  const image = media?.variations
    ? getVariation(media.variations, ImageStyleAvailable.TeaserSquareRatio11)
    : null;
  return (
    <div
      id={`list-item-${parentID}-${listPosition}`}
      className={cn(
        "teaser-news relative flex flex-col items-stretch group",
      )}
    >
      <HorizontalListVisibilityTracker
        listTitle={parentTitle}
        listType={parentType}
        componentID={parentID}
        componentName={parentType}
        componentPosition={position}
        componentCount={count}
        listElementTitle={title}
        listElementPosition={listPosition}
        listElementCount={listCount}
        imageSource={image?.url ?? ""}
        targetId={`list-item-${parentID}-${listPosition}`}
      />
      <div className="relative w-full h-[220px] lg:h-[300px] overflow-hidden flex shrink-0 items-center justify-center rounded-lg">
        {image && (
          <>
            <DrupalImage
              url={mobileImage.url}
              alt={ media.alt || title}
              width={mobileImage.width}
              height={mobileImage.height}
              className="w-full transition-transform duration-300 border sm:hidden xl:group-hover:scale-105 h-full object-cover"
            />
            <DrupalImage
              url={image.url}
              alt={ media.alt || title}
              width={image.width}
              height={image.height}
              sizes="33vw"
              className="hidden sm:block w-full transition-transform duration-300 xl:group-hover:scale-105 h-full object-cover"
            />
            {play_button && (
              <span className="w-12 h-12 rounded-full border-red border opacity-0 text-white bg-red scale-110 group-hover:opacity-100 inline-flex items-center justify-center group-hover:scale-100  absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 transition-all duration-500">
                <Icon name="play-thin" className=" h-4 w-4"/>
              </span>
            )}
          </>
        )}
      </div>
      <div className="flex flex-1 mt-2 lg:mt-3">
        <div className="w-full h-full flex flex-col">
          {thema && (
            <div className="mb-1 flex gap-1 flex-row font-semibold">
              {thema.map((themaItem, index) => (
                <span key={themaItem.id} className="flex gap-1 flex-row teaser-meta">
                    {index !== 0 &&
                      <span
                        className={cn(
                          "text-sm  teaser-meta__divider",
                          bgColor ? `${mapTonSurTonColor[bgColor].light.text} ` : "text-yellow-700/20",
                        )}
                      > | </span>}
                  <span
                    className={cn(
                      "text-sm teaser-meta__label\"",
                      bgColor ? `${mapTonSurTonColor[bgColor].light.text} ` : "text-yellow-700",
                    )}
                  >{themaItem.name}</span>
                  </span>
              ))}
            </div>
          )}

          {link && title && (
            <NewsLink
              data={{
                link: link.url,
                title: title,
                imageUrl: image?.url,
              }}
              count={count ? count.toString() : ""}
              position={position ? position.toString() : ""}
              parentTitle={parentTitle}
              parentType={parentType}
              parentID={parentID}
              listElementCount={listCount}
              listElementPosition={listPosition}
              listElementTitle={title}
            />
          )}
        </div>
      </div>
    </div>
  );
};
