'use client';

import { cn } from "@/helpers/className";
import Image from 'next/image';
import ListClickTracking from "@/components/tracking/ListClickTracking.tsx";
import React from "react";
import HorizontalListVisibilityTracker from "@/components/tracking/HorizontalListVisibilityTracker.tsx";

interface PodcastTeaserProps {
  data: {
    title: string;
    description: string;
    image: {
      templateUrl: string;
      alt: string;
      focalPoint: any;
    };
    actionItems: [
      {
        action: {
          link: string;
          linkType: string;
        };
      }
    ];
  };
  position: number;
  listPosition: number;
  count: number;
  listCount: number;
  parentTitle: string;
  parentType: string;
  parentID: string;
}

export const PodcastTeaser: React.FC<PodcastTeaserProps> = ({
                                                              data,
                                                              listCount,
                                                              count,
                                                              listPosition,
                                                              position,
                                                              parentTitle,
                                                              parentType,
                                                              parentID,
                                                            }) => {
  const imageUrl = data.image.templateUrl.replace('orig', 'w320hx');

  // Find the action item where linkType is 'podcastEpisode'
  const podcastEpisodeActionItem = data.actionItems.find(
    (item) => item.action.linkType === 'podcastProgram'
  );

  // If found, use its link, otherwise fallback to the first action item's link
  const link = podcastEpisodeActionItem
    ? podcastEpisodeActionItem.action.link
    : data.actionItems[0]?.action.link;

  const handleListClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault(); // Prevent the default link behavior

    ListClickTracking({
      listTitle: parentTitle,
      listType: parentType,
      componentName: parentType,
      componentID: parentID,
      componentCount: String(count),
      componentPosition: String(position),
      imageSource: imageUrl,
      targetUrl: link,
      targetPageId: link,
      listElementTitle: data.title,
      listElementPosition: listPosition,
      listElementCount: listCount,
      linkTitle: data.title,
      linkType: 'standard', // Assuming this is a standard link type
    });

    // Navigate to the URL after tracking
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <div
      id={`list-item-${parentID}-${listPosition}`}
      className={cn('podcast-teaser flex flex-col items-center')}
    >
      <HorizontalListVisibilityTracker
        listTitle={parentTitle}
        listType={parentType}
        componentID={parentID}
        componentName={parentType}
        componentPosition={position}
        componentCount={count}
        listElementTitle={data.title}
        listElementPosition={listPosition}
        listElementCount={listCount}
        imageSource={imageUrl}
        targetId={`list-item-${parentID}-${listPosition}`}
      />
      <a
        href={link}
        target={'_blank'}
        rel={'noopener noreferrer'}
        className={
          'rounded-lg overflow-hidden border hover:opacity-80 transition-all'
        }
        onClick={handleListClick}
      >
        <Image
          src={imageUrl}
          alt={data.title || 'Podcast image'}
          className="w-[40vw] md:[30vw] aspect-square lg:w-64 lg:h-64 object-cover"
          width={320}
          height={320}
        />
        <h5 className="mt-2 text-lg hidden">{data.title}</h5>
      </a>
      {/*<p className="mt-2 hidden">{data.description}</p>*/}
    </div>
  );
};