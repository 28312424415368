import { cn } from "@/helpers/className";
import ListImpressionTracking from "@/components/tracking/ListImpressionTracking.tsx";
import React from "react";
import VerticalListVisibilityTracker from "@/components/tracking/VerticalListVisibilityTracker.tsx";

interface AccordionItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  isActive: boolean;
  onToggle: () => void;
  position: number;
  listPosition: number;
  count: number;
  listCount: number;
  parentTitle: string;
  parentType: string;
  parentID: string;
}

const AccordionItem = ({ isActive, onToggle, data, position, listPosition, listCount, count, parentID, parentTitle, parentType }: AccordionItemProps) => {
  return (
    <div
      id={`list-item-${parentID}-${listPosition}`}
      className={cn("accordion-item mb-3", isActive ? "active" : "")}
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      <VerticalListVisibilityTracker
        listTitle={parentTitle}
        listType={parentType}
        componentID={parentID}
        componentName={parentType}
        componentPosition={position}
        componentCount={count}
        listElementTitle={data.paragraphAccordeonItemTitle ? data.paragraphAccordeonItemTitle : data.faqRef.title}
        listElementPosition={listPosition}
        listElementCount={listCount}
        imageSource={data?.imageUrl ?? ""}
        targetId={`list-item-${parentID}-${listPosition}`}
      />
      <div>
        <button
          className={cn(
            "btn w-full justify-start border-b rounded-none border-b-grey/60 hover:border-b-blue pb-4 font-semibold px-0 transition-colors text-left group",
            isActive ? "text-blue border-b-blue" : ""
          )}
          itemProp="name"
          aria-expanded={isActive}
          id={`accordion-button-${data.paragraphAccordeonItemTitle || data.faqRef.title}`}
          data-accordion-controls={`#accordion-content-${data.paragraphAccordeonItemTitle || data.faqRef.title}`}
          aria-disabled="false"
          tabIndex={0}
          onClick={onToggle}
        >
          {data.paragraphAccordeonItemTitle ? data.paragraphAccordeonItemTitle : data.faqRef.title}
          <svg
            className={cn(
              "shrink-0 ml-auto transition-colors group-hover:fill-blue",
              isActive ? "fill-blue" : "fill-grey"
            )}
            width="16"
            height="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="7" width="16" height="2" rx="1" className={`transform origin-center transition duration-200 ease-out ${isActive && '!rotate-180'}`} />
            <rect y="7" width="16" height="2" rx="1" className={`transform origin-center rotate-90 transition duration-200 ease-out ${isActive && '!rotate-180'}`} />
          </svg>
        </button>
        <div
          id={`#accordion-content-${data.paragraphAccordeonItemTitle ? data.paragraphAccordeonItemTitle : data.faqRef.title}`}
          role="region"
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          aria-labelledby={`accordion-button-${data.paragraphAccordeonItemTitle ? data.paragraphAccordeonItemTitle : data.faqRef.title}`}
          className={cn(
            "accordion-item__content py-4 flex-col prose max-w-none",
            isActive ? "flex animate-[fadeIn_both_.3s]" : "hidden"
          )}
        >
          <div
            itemProp="text"
            dangerouslySetInnerHTML={{
              __html: data?.paragraphAccordeonItemText?.processed ? data.paragraphAccordeonItemText.processed : (data.faqRef.answer ? data.faqRef.answer.processed : '') as string,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { AccordionItem };