'use client'

interface ListImpressionTrackingProps {
  listTitle?: string;
  listType?: string;
  componentName: string;
  componentID: string;
  componentPosition: number;
  componentCount: number;
  imageSource?: string;
  listElementTitle: string;
  listElementPosition: number;
  listElementCount: number;
}

const ListImpressionTracking = ({
                                  listTitle,
                                  listType,
                                  componentName,
                                  componentID,
                                  componentCount,
                                  componentPosition,
                                  imageSource,
                                  listElementTitle,
                                  listElementPosition,
                                  listElementCount,
                                }: ListImpressionTrackingProps) => {
  // Initialize the digitalData object if it doesn't already exist
  window.digitalData = window.digitalData || {};
  window.digitalData.events = window.digitalData.events || [];

  // Push the new event
  window.digitalData.events.push({
    event: {
      schema: "iglu:be.vrt/list_impression/jsonschema/1-1-0",
      data: {
        timestamp: Date.now(),
      },
    },
    context: [
      {
        schema: "iglu:be.vrt/context_list/jsonschema/1-1-3",
        data: {
          listtitle: listTitle,
          listtype: listType,
          componentname: componentName,
          componentid: componentID,
          componentposition: componentPosition,
          componentcount: componentCount,
          containerid: componentID,
        },
      },
      {
        schema: "iglu:be.vrt/context_listelement/jsonschema/1-1-1",
        data: {
          listelementtitle: listElementTitle,
          listelementposition: listElementPosition,
          listelementcount: listElementCount,
          imagesource: imageSource,
        },
      },
    ],
  });

  return null; // Render nothing
};

export default ListImpressionTracking;