import React, { useEffect, useState } from 'react';
import {cn} from "@/helpers/className";
import {formatDistanceToNow, parseISO} from 'date-fns';
import {nl} from 'date-fns/locale';
import Image from "next/image";
import Link from "next/link";
import ListLink from "@/components/atoms/ListLink.tsx";
import HorizontalListVisibilityTracker from "@/components/tracking/HorizontalListVisibilityTracker.tsx";

interface SporzaProps {
    data: {
        imageUrl: string,
        link: string,
        publishedDate: string, // Update this property name
        title: string,
    },
  position: number;
  listPosition: number;
  count: number;
  listCount: number;
  parentTitle: string;
  parentType: string;
  parentID: string;
}

export const SporzaTeaser = ({data, listCount, count, listPosition, position, parentTitle, parentType, parentID}: SporzaProps) => {
    const [timeElapsed, setTimeElapsed] = useState('');

    useEffect(() => {
        const publishedDate = parseISO(data.publishedDate);
        const formattedTimeElapsed = formatDistanceToNow(publishedDate, {
            addSuffix: true,
            locale: nl, // Set the locale to Dutch
        });
        setTimeElapsed(formattedTimeElapsed);
    }, [data.publishedDate]);
    return (
        <div
          id={`list-item-${parentID}-${listPosition}`}
          className={cn(
                "teaser-sporza relative flex flex-col items-stretch group",
            )}
        >
          <HorizontalListVisibilityTracker
            listTitle={parentTitle}
            listType={parentType}
            componentID={parentID}
            componentName={parentType}
            componentPosition={position}
            componentCount={count}
            listElementTitle={data.title}
            listElementPosition={listPosition}
            listElementCount={listCount}
            imageSource={data?.imageUrl ?? ""}
            targetId={`list-item-${parentID}-${listPosition}`}
          />
            <div className="relative w-full h-[160px] md:h-[200px] xl:h-[220px] 2xl:h-[240px] overflow-hidden flex shrink-0 items-center justify-center rounded-lg">
                <Image
                    src={data.imageUrl}
                    alt={data.title || 'VRT Sporza image'}
                    className="w-full transition-transform duration-300 xl:group-hover:scale-105 h-full object-cover image-scale"
                    width={600}
                    height={400}
                />
            </div>

            <div className="flex flex-1 mt-2 lg:mt-3">
                <div className="w-full h-full flex flex-col">
                    {data.link && data.title && (
                      <ListLink
                        data={data}
                        count={String(count)}
                        position={String(position)}
                        parentTitle={parentTitle}
                        parentType={parentType}
                        parentID={parentID}
                        listElementCount={listCount}
                        listElementTitle={data.title}
                        listElementPosition={listPosition}
                      />
                    )}
                    {timeElapsed && <p className="mt-2 text-sm">{timeElapsed}</p>}
                </div>
            </div>
        </div>


    );
};